import React, { useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import './styles.css';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { atualizarPerfil } from '../../../../store/modules/parceiro/actions';

const ModalCep = (props) => {
  const dispatch = useDispatch();
  const parceiro = useSelector((state) => {
    return state.parceiro
  })

  const [endCep, setEndCep] = useState('');
  const [cep, setCep] = useState('');
  const [cepValido, setCepValido] = useState('');
  const [rua, setRua] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');

  const buscarCep = async (element) => {
    let input = element.target;
    let cep = input.value.replace(/[^\d]/g, '');
    if (!cep) return;
    const resultado = await axios.get(`https://viacep.com.br/ws/${cep}/json`);
    if (!resultado || resultado.data.erro) {
      toast.error('CEP inválido', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setCep('');
    } else {
      setCepValido(cep);
      setRua(resultado.data.logradouro);
      setBairro(resultado.data.bairro);
      setCidade(resultado.data.localidade);
      setEstado(resultado.data.uf);
    }
  };

  const atualizarParceiro = async (e) => {
    e.preventDefault();
    if (!cepValido) return;

    const data = {
      entregaRua: rua,
      entregaBairro: bairro,
      entregaCidade: cidade,
      entregaEstado: estado,
      entregaCep: cepValido,
      informouCep: true
    };

    data.entregaCep = data.entregaCep.replace(/[^\d]/g, '');

    dispatch(atualizarPerfil(data));
  };

  return (
    <Modal
      show={!parceiro.informouCep}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      keyboard={false}
      backdrop="static"
    >
      <div id="modal-container">
        <h3>Informe seu CEP</h3>
        <p>Para calcular o frete para a sua região.</p>
        <p>O frete será calculado na hora de finalizar o resgate.</p>
        <form onSubmit={atualizarParceiro}>
          <InputMask
            id="endCep"
            name="endCep"
            type="text"
            placeholder="_____-___"
            mask="99999-999"
            value={cep}
            onBlur={buscarCep}
            onChange={(e) => setCep(e.target.value)}
          ></InputMask>
          <button
            type="submit"
            className="btn-cep"
            disabled={!cep ? true : false}
          >BUSCAR</button>
        </form>
      </div>
    </Modal>
  )
};

export default ModalCep;
