/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container, Row, Col } from 'react-bootstrap';
import api from '../../../services/API';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import sortFunc from '../../../utils/ordernarColunaInt';

const { SearchBar } = Search;

export default function Cartoes() {
  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'rede',
      text: 'Rede',
      sort: true,
    },
    {
      dataField: 'parceiro',
      text: 'Vendedor Loja',
      sort: true,
    },
    {
      dataField: 'codigoBarras',
      text: 'Proxy',
      sort: true,
    },
    {
      dataField: 'numero',
      text: 'Número Cartão',
      sort: true,
    },
    {
      dataField: 'saldo',
      text: 'Saldo',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.saldo)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'nome',
      text: 'Nome Cartão',
      sort: true,
    },
  ];

  useEffect(() => {
    buscarRedes();
    buscarCartoes();
  }, []);

  const buscarRedes = async () => {
    const resultado = await api.get('/api/redes?ativo=1');
    if (resultado) {
      setRedesSelect(resultado.data.redes);
    }
  };

  const buscarCartoes = async () => {
    setBuscando(true);
    const resultado = await api.get(`/api/cartoes?rede=${redeSelecionada}`);
    if (resultado) {
      setData(resultado.data.cartoes);
    }
    setBuscando(false);
  };

  const [data, setData] = useState([]);
  const [redeSelecionada, setRedeSelecionada] = useState('');
  const [redesSelect, setRedesSelect] = useState([]);
  const [buscando, setBuscando] = useState(false);

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'asc',
    },
  ];

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Cartões</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row>
        <Col xs={12} sm={6} md={4} lg={3} className="d-flex flex-column">
          <label htmlFor="redeSelecionada">Selecione uma Rede</label>
          <select
            className="input-select mb-3"
            name="redeSelecionada"
            id="redeSelecionada"
            onChange={(e) => setRedeSelecionada(e.target.value)}
            defaultValue=""
            placeholder=""
          >
            <option value="">Todas</option>
            {redesSelect.map((rede) => (
              <option value={rede.nome} key={rede.nome}>
                {rede.nome}
              </option>
            ))}
          </select>
        </Col>
        <Col
          xs={12}
          sm={1}
          className="d-flex flex-column justify-content-center align-items-center d-sm-block"
        >
          <button className="btn-busca" type="submit" onClick={buscarCartoes}>
            {buscando ? (
              <i className="fas fa-circle-notch fa-spin"></i>
            ) : (
              <i className="fas fa-search"></i>
            )}
          </button>
        </Col>
      </Row>
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse">
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar"
                  className="input-theme"
                />
              </div>
              <BootstrapTable
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory(options)}
                defaultSorted={defaultSorted}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </Container>
  );
}
