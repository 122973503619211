/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, ListGroup } from 'react-bootstrap';
import api from '../../../services/API';

export default function ModalObsAntifraude({
  show,
  onHide,
  rede,
  observacao,
  codProduto,
  atualizarObsTabela,
}) {
  const [obs, setObs] = useState('');
  const [loading, setLoading] = useState(false);
  const [historico, setHistorico] = useState([]);

  useEffect(() => {
    if (show) {
      setObs(observacao || '');
      buscarHistorico();
    }
  }, [show, observacao, rede, codProduto]);

  const buscarHistorico = async () => {
    try {
      const response = await api.get(`/api/antifraude/historico/${rede}/${codProduto}`);
      console.log('Resposta do histórico:', response.data);

      if (response.data && response.data.historico) {
        const historicoObservacoes = response.data.historico.filter(
          item => item.tipo === 'atualizouObsAntifraude' || 
                 (item.tipo === 'liberouQuantidadeAntifraude' && item.liberacaoEmMassa)
        );
        
        console.log('Histórico filtrado:', historicoObservacoes);
        
        const historicoOrdenado = historicoObservacoes.sort(
          (a, b) => new Date(b.data) - new Date(a.data)
        );

        setHistorico(historicoOrdenado);
      }
    } catch (error) {
      console.error('Erro ao buscar histórico:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await api.post('/api/antifraude/observacao', {
        codProduto,
        rede,
        observacao: obs,
      });

      if (response.status === 200) {
        atualizarObsTabela(obs, rede, codProduto);
        await buscarHistorico();
        onHide();
      }
    } catch (error) {
      console.error('Erro ao salvar observação:', error);
    }
    setLoading(false);
  };

  const renderArquivo = (arquivo) => {
    if (!arquivo) return null;
    
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const url = `${baseUrl}/arquivos/antifraude/${arquivo}`;
    
    return (
      <a 
        href={url} 
        target="_blank" 
        rel="noopener noreferrer" 
        className="text-primary ml-2"
      >
        <i className="fas fa-file-alt"></i> Ver comprovante
      </a>
    );
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Editar Observação</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Observação</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={obs}
              onChange={(e) => setObs(e.target.value)}
              required
            />
          </Form.Group>

          {historico.length > 0 && (
            <div className="mt-4">
              <h6>Histórico de Comentários Recentes</h6>
              <ListGroup>
                {historico.map((item, index) => {
                  const isLiberacaoMassa = item.tipo === 'liberouQuantidadeAntifraude' && item.liberacaoEmMassa;
                  
                  return (
                    <ListGroup.Item key={index} className="small">
                      <div className="d-flex justify-content-between align-items-center">
                        <span>
                          <strong>{item.usuario || 'Sistema'}:</strong>{' '}
                          {isLiberacaoMassa ? item.comentario : item.comentario}
                          {item.arquivo && renderArquivo(item.arquivo)}
                        </span>
                        <small className="text-muted">
                          {new Date(item.data).toLocaleString()}
                        </small>
                      </div>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? 'Salvando...' : 'Salvar'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
