/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/API';

export default function CorrecaoPontuacao() {
  const [analisando, setAnalisando] = useState(false);
  const [data, setData] = useState([]);

  const buscarCorrecoes = async (e) => {
    e.preventDefault();
    setAnalisando(true);
    const resultado = await api.get('/api/pontuacoes/correcoes');

    if (resultado) {
      if (resultado.data.vendasCorrigir.length === 0) {
        toast.success(
          'Nenhuma venda com pontuação para corrigir foi encontrada',
          {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 10000,
          }
        );
      }
      setData(resultado.data.vendasCorrigir);
    }
    setAnalisando(false);
  };

  const corrigirPontuacao = async (e) => {
    const { target } = e;
    e.preventDefault();

    const idVenda = target.value;

    target.disabled = true;
    target.innerHTML = 'CORRIGINDO...';

    const resultado = await api.post(`/api/pontuacoes/corrigir/${idVenda}`);
    if (resultado) {
      toast.success(resultado.data.mensagem, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setData(data.filter((venda) => venda.idVenda !== Number(idVenda)));
    } else {
      target.disabled = false;
      target.innerHTML = 'CORRIGIR';
    }
  };

  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Correção de Pontuação</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <Row>
          {data.map((venda) => (
            <Col
              xs={12}
              className="d-flex flex-column mb-3"
              key={venda.idVenda}
            >
              <Row>
                <Col xs={12} md={6} className="d-flex flex-column">
                  <span>
                    <b>Revenda </b>
                    {venda.revenda}
                  </span>
                  <span>
                    <b>Parceiro </b>
                    {venda.parceiro}
                  </span>
                  <span>
                    <b>CPF </b>
                    {venda.cpf.replace(
                      /(\d{3})(\d{3})(\d{3})(\d{2})/,
                      '$1.$2.$3-$4'
                    )}
                  </span>
                  <span>
                    <b>Número Fiscal </b>
                    {venda.identificador}
                  </span>
                  <span>
                    <b>Pontuado </b>
                    {Number(venda.pontuacaoAtual).toLocaleString('pt-BR')}
                  </span>
                  <span>
                    <b>Nova pontuação </b>
                    {Number(venda.pontuacaoNova).toLocaleString('pt-BR')}
                  </span>
                  <span>
                    <b>A pontuar </b>
                    {Number(venda.aPontuar).toLocaleString('pt-BR')}
                  </span>
                </Col>
                <Col
                  xs={12}
                  md={6}
                  className="d-flex justify-content-center align-items-center"
                >
                  <button
                    className="btn-primario px-3"
                    type="submit"
                    onClick={corrigirPontuacao}
                    value={venda.idVenda}
                  >
                    CORRIGIR
                  </button>
                </Col>
                <hr className="hr-loja" />
              </Row>
            </Col>
          ))}
        </Row>
        <form className="form" onSubmit={buscarCorrecoes}>
          {analisando ? (
            <button disabled className="btn-primario" type="submit">
              ANALISANDO...
            </button>
          ) : (
            <button className="btn-primario" type="submit">
              ANALISAR CORREÇÕES
            </button>
          )}
        </form>
      </div>
    </Container>
  );
}
