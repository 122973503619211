import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Col } from 'react-bootstrap';
import './styles.css';
import { useSelector } from 'react-redux';

function NavMobileToggler() {
  return <i className="fas fa-list nav-menu" />;
}

export default function NavbarMobile() {
  const parceiro = useSelector((state) => {
    return state.parceiro;
  });

  const fecharMenu = () => {
    const navbar = document.getElementsByClassName(
      'navbar-collapse collapse show'
    );
    navbar[0].classList.remove('show');
  };

  return (
    <Navbar id="navbar" className="w-100" expand="xs">
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        children={<NavMobileToggler />}
        className="w-auto mb-1"
      />
      <Col xs={8}>
        <Link to="/loja">
          <img
            className="w-100 nav-logo"
            src="/imagens/logo.png"
            alt="Logo Integralmédica"
          ></img>
        </Link>
      </Col>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Item onClick={fecharMenu}>
            <Link className="nav-link" to="/loja">
              Home
            </Link>
          </Nav.Item>
          <Nav.Item onClick={fecharMenu}>
            <Link className="nav-link" to="/perfil">
              Meu Perfil
            </Link>
          </Nav.Item>
          <Nav.Item onClick={fecharMenu}>
            <Link className="nav-link" to="/pontuacoes">
              Pontuações
            </Link>
          </Nav.Item>
          <Nav.Item onClick={fecharMenu}>
            <Link className="nav-link" to="/resgates">
              Resgates
            </Link>
          </Nav.Item>
          {/*<Nav.Item onClick={fecharMenu}>
            <Link className="nav-link" to="/cartao">
              Cartão
            </Link>
  </Nav.Item>*/}
          {parceiro.tipoPontuacaoRevenda ? (
            <Nav.Item onClick={fecharMenu}>
              <Link className="nav-link" to="/enviar-pontuacao">
                Enviar Pontuação
              </Link>
            </Nav.Item>
          ) : null}
          <Nav.Item onClick={fecharMenu}>
            <Link className="nav-link" to="/pontos-produtos">
              Pontos por Produto
            </Link>
          </Nav.Item>
          <Nav.Item onClick={fecharMenu}>
            <Link className="nav-link" to="/pontos-produtos">
              Fotos e Vídeos
            </Link>
          </Nav.Item>
          <Nav.Item onClick={fecharMenu}>
            <Link className="nav-link" to="/regulamento">
              Regulamento
            </Link>
          </Nav.Item>
          <Nav.Item onClick={fecharMenu}>
            <Link className="nav-link" to="/contato">
              Contato
            </Link>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
