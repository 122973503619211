import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { atualizarSaldo } from '../../../store/modules/parceiro/actions';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import './styles.css';
import sortFunc from '../../../utils/ordernarColunaInt';
import moment from 'moment-timezone';

const formatarPontos = (celula, valor) => {
  return Number(valor.totalPontos).toLocaleString('pt-BR');
};

const formatarDataValidade = (celula, valor) => {
  return moment(valor.venceEm).format('DD/MM/YYYY HH:mm:ss');
};

const formatarData = (celula, valor) => {
  return moment(valor.pontuadoEm).format('DD/MM/YYYY HH:mm:ss');
};

const columns = [
  {
    dataField: 'totalPontos',
    text: 'Pontos',
    formatter: formatarPontos,
    sortFunc: sortFunc,
    sort: true,
  },
  {
    dataField: 'pontuadoEm',
    text: 'Data da Pontuação',
    formatter: formatarData,
    sort: true,
  },
  {
    dataField: 'validadePontos',
    text: 'Vencimento da Pontuação',
    formatter: formatarDataValidade,
  },
];

const defaultSorted = [
  {
    dataField: 'pontuadoEm',
    order: 'desc',
  },
];

export default function Pontuacoes() {
  const dispatch = useDispatch();
  const parceiro = useSelector(state => state.parceiro);

  useEffect(() => {
    buscarPontuacoes();
    dispatch(atualizarSaldo(parceiro.cpf));
  }, []);

  const buscarPontuacoes = async () => {
    setBuscando(true);
    const resultado = await api.get('/api/pontuacoes');
    if (resultado) {
      setData(resultado.data.pontuacoes);
    }
    setBuscando(false);
  };

  const [data, setData] = useState([]);
  const [buscando, setBuscando] = useState(false);
  return (
    <Container className="mt-5 mb-5">
      <div>
        <h5>PONTUAÇÕES</h5>
        <hr className="hr-loja mt-0" />
      </div>
      <div>
        <BootstrapTable
          bootstrap4
          noDataIndication={() =>
            buscando ? (
              <span>
                Carregando
                <i className="fas fa-circle-notch fa-spin ml-3"></i>
              </span>
            ) : (
              <span>Sem resultados</span>
            )
          }
          className="tabela-fundo-claro"
          keyField="id"
          data={data}
          columns={columns}
          pagination={paginationFactory()}
          defaultSorted={defaultSorted}
          headerClasses="tabela-header-claro"
          bodyClasses="tabela-body-claro"
        />
      </div>
    </Container>
  );
}
