import React, { useState, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import './styles.css';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import { format } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { atualizarSaldo } from '../../../store/modules/parceiro/actions';
import NumberFormat from 'react-number-format';
import historico from '../../../services/Historico';

export default function UploadFoto() {
  const [enviando, setEnviando] = useState(false);
  const [arquivos, setArquivos] = useState(null);
  const [codigo, setCodigo] = useState();
  const [valorUnitario, setValorUnitario] = useState('');
  const [auxiliarValorUnitario, setAuxiliarValorUnitario] = useState('');
  const [status, setStatus] = useState('Procurar...');
  const [erroInput, setErroInput] = useState({
    erroDados: false,
    erroTotal: false,
    erroProdutoInvalido: false,
    erroSaldo: false,
  });
  const [itens, setItens] = useState([]);
  const [cpf, setCpf] = useState('');
  const [qtde, setQtde] = useState(1);
  const [saldo, setSaldo] = useState();
  const [parceiro, setParceiro] = useState('');
  const [valorDescontoItem, setValorDescontoItem] = useState(null);
  const [total, setTotal] = useState(0);
  const [totalVenda, setTotalVenda] = useState(0);
  const [descontos, setDescontos] = useState(null);
  const [tipoFiscal, setTipoFiscal] = useState('');
  const [identificador, setIdentificador] = useState('');
  const [realizadoEm, setRealizadoEm] = useState('');
  const [enviarMensagem, setEnviarMensagem] = useState(false);
  const [nomeArquivo, setNomeArquivo] = useState(null);
  const [codRevenda, setCodRevenda] = useState('');

  const dispatch = useDispatch();
  const parceiroRedux = useSelector((state) => state.parceiro);

  const validarCampos = (dados) => {
    const entries = Object.entries(dados);
    let temNulo = false;
    entries.forEach((param) => {
      if (temNulo) return;
      if (param[0] === 'itens' && param[1].length === 0) {
        toast.error(`Adicione itens antes de processar`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
      if (
        param[1] === '' &&
        param[0] !== 'totalVenda' &&
        param[0] !== 'totalDescontos' &&
        param[0] !== 'tipoFiscal' &&
        param[0] !== 'identificador' &&
        param[0] !== 'idFoto'
      ) {
        const elemento = document.getElementsByName(param[0])[0];
        toast.error(`${param[0]} obrigatório`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
    });
    return temNulo;
  };

  const validarDataVenda = (e) => {
    const data = e.replace(/[^\d]/g, '');
    if (!data) return;
    if (data.length < 8) {
      toast.error(`Data inválida`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setRealizadoEm('');
    }
    return data;
  };

  function formatoMoeda(value) {
    if (!Number(value)) return '';
    const quantia = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);
    return `${quantia}`;
  }

  const calcularAuxiliarSaldo = (codigo, saldo) => {
    let qtdPorCodigo = 0;

    for (let i = 0; i < itens.length; i++) {
      if (itens[i].codigo === codigo) {
        qtdPorCodigo += 1;
      }
    }

    if (qtdPorCodigo <= saldo) return true;
    return false;
  };

  const upload = async (e) => {
    e.preventDefault();

    if (!arquivos) {
      toast.error(`Selecione um arquivos primeiro`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    let formData = new FormData();

    for (let i = 0; i < arquivos.length; i++) {
      formData.append('arquivos', arquivos[i]);
    }
    setEnviando(true);
    const resultado = await api.post('/api/upload/venda_foto', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (resultado) {
      toast.success(
        `${Number(resultado.data.total).toLocaleString(
          'pt-BR'
        )} arquivo(s) enviado(s) com sucesso`,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        }
      );
      setArquivos(null);
      setStatus('Procurar...');
    }
    setEnviando(false);
  };

  const delItem = (e) => {
    e.preventDefault();
    const posicao = Number(e.target.id);
    setDescontos(null);
    setTotal(Number(total) - Number(itens[posicao].subTotal));
    setItens(itens.filter((item, index) => index !== posicao));
  };

  const enviarVenda = async (e) => {
    e.preventDefault();

    if (total === 0 || !itens) {
      return setErroInput((state) => ({ erroDados: true, erroTotal: true }));
    }
    setErroInput((state) => ({ erroDados: false, erroTotal: false }));

    try {
      if (!cpf) return;
      setEnviando(true);

      const dataAtual = format(new Date(), 'dd/MM/yyyy');
      validarDataVenda(dataAtual);

      const totalItensLiberados = itens.filter((item) => item.liberado);
      let suporteValorUnitario = 0;
      let totalVendaAjustada =
        totalItensLiberados.length > 0
          ? totalItensLiberados.forEach((item, index) => {
              if (item.valorUnitario)
                suporteValorUnitario += item.valorUnitario;
            })
          : totalItensLiberados.length === 0 || totalItensLiberados === 0
          ? 0
          : Number(totalItensLiberados[0].valorUnitario);

      const venda = {
        cpf,
        tipoFiscal,
        identificador,
        realizadoEm: dataAtual,
        totalVenda:
          totalItensLiberados.length > 0
            ? suporteValorUnitario
            : totalVendaAjustada,
        totalDescontos: null,
        itens,
        enviarMensagem,
        nomeArquivo,
      };

      const temNulo = validarCampos(venda);
      if (temNulo) {
        return console.log('Tem nulo em Validar Campos');
      }

      venda.cpf = venda.cpf.replace(/[^\d]/g, '');
      venda.realizadoEm = venda.realizadoEm.replace(/[^\d]/g, '');
      venda.realizadoEm = `${venda.realizadoEm.substring(
        4
      )}${venda.realizadoEm.substring(2, 4)}${venda.realizadoEm.substring(
        0,
        2
      )}`;

      await api.post('/api/pontuacoes/pontuar', {
        venda,
        enviarMensagem,
      });

      toast.success('Venda processada com sucesso.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });

      historico.push('/pontuacoes');
    } catch (err) {
      toast.error('Erro ao tentar enviar a venda.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });

      setItens([]);
      setCpf('');
      setParceiro('');
      setTipoFiscal(null);
      setIdentificador(null);
      setRealizadoEm('');
      setCodRevenda('');
      setTotal('');
      setDescontos('');
      setEnviando(false);
    }
  };

  const pegarForm = (e) => {
    e.preventDefault();

    if (!codigo || !valorUnitario)
      return setErroInput((state) => ({
        ...state,
        erroDados: true,
        erroProdutoInvalido: false,
        erroSaldo: false,
      }));
    setErroInput((state) => ({ ...state, erroDados: false }));

    // Consultar se o produto existe
    api
      .get(`api/produtos/${codigo}/codigo`)
      .then((prod) => {
        if (prod.data.produtos.length < 1) {
          setErroInput((state) => ({
            ...state,
            erroProdutoInvalido: true,
            erroSaldo: false,
          }));
          console.log('Insira um produto válido.');
          return;
        }
        setErroInput((state) => ({ ...state, erroProdutoInvalido: false }));

        // Historico
        api
          .get(`api/historico/codigoUsado/${codigo}/${cpf}`)
          .then((res) => {
            if (res.data === null)
              return setErroInput((state) => ({ ...state, erroSaldo: true }));
            setErroInput((state) => ({ ...state, erroSaldo: false }));
            let qtdProdutoComprados = parseInt(res.data.quantidade);
            let idRevenda = parseInt(res.data.idRevenda);

            // Quantidade vendida
            api
              .get(`api/vendas_itens/${codigo}/${idRevenda}`)
              .then((itensVendidos) => {
                setErroInput((state) => ({ ...state, erroSaldo: false }));
                setSaldo(qtdProdutoComprados - itensVendidos.data);

                if (itens.length !== 0) {
                  let temSaldoLista = calcularAuxiliarSaldo(
                    codigo,
                    qtdProdutoComprados - itensVendidos.data
                  );
                }

                // Pontos por produto
                api
                  .get(`api/produtos/${codigo}/codigo`)
                  .then((produtoBd) => {
                    let produtoConsultado = produtoBd.data.produtos[0];

                    const auxiliarLiberado = (codigo, saldo) => {
                      let qtdPorCodigo = 0;

                      for (let i = 0; i < itens.length; i++) {
                        if (itens[i].codigo === codigo) {
                          qtdPorCodigo += 1;
                        }
                      }

                      let saldoAjuste = saldo;

                      if (saldoAjuste < 0) saldoAjuste = 0;
                      if (saldoAjuste >= 1 && saldoAjuste > qtdPorCodigo)
                        return true;
                      return false;
                    };

                    let produtoDesejado = {
                      ...produtoConsultado,
                      qtde,
                      liberado: auxiliarLiberado(
                        produtoConsultado.codigo,
                        Number(qtdProdutoComprados - itensVendidos.data)
                      ),
                      valorUnitario,
                      valorDesconto: valorDescontoItem,
                      subTotal: valorUnitario,
                    };
                    setItens([...itens, produtoDesejado]);
                  })
                  .catch((err) =>
                    console.log('Erro de consulta do produto', err)
                  );
              })
              .catch((err) => {
                setErroInput((state) => ({ ...state, erroSaldo: true }));
                console.log(
                  'Todos os itens ja foram vendidos ou esse produto não foi comprado.',
                  err
                );
              });
          })
          .catch((err) => {
            setErroInput((state) => ({ ...state, erroSaldo: true }));
            console.log(
              'Erro ao requisitar o historico do produto. Você não tem histórico de compra deste produto',
              err
            );
          });
      })
      .catch((err) => {
        setErroInput((state) => ({ ...state, erroProdutoInvalido: true }));
        console.log('Insira um produto válido.');
      });
  };

  useEffect(() => {
    setCpf(parceiroRedux.cpf);
    dispatch(atualizarSaldo(parceiroRedux.cpf));

    let valor = auxiliarValorUnitario;
    let digito = Math.floor(valor.length - 2);
    let novoValorUnitario = Number(
      valor.substring(0, digito) + '.' + valor.substring(digito)
    );
    setValorUnitario(novoValorUnitario);

    (function calcularTotal() {
      if (itens) {
        let totalPontos = 0,
          totalEmVendas = 0;
        for (let i = 0; i < itens.length; i++) {
          totalPontos = totalPontos + parseInt(itens[i].valorPontos);
          totalEmVendas += itens[i].valorUnitario;
        }
        setTotal(totalPontos);
        setTotalVenda(totalEmVendas);
      }
    })();
  }, [itens, cpf, auxiliarValorUnitario, saldo, parceiroRedux.cpf, dispatch]);

  return (
    <Container className="mt-5 mb-5">
      <div>
        <h5>ENVIAR PONTUAÇÃO</h5>
        <hr className="hr-loja mt-0" />
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center">
        {parceiroRedux?.tipoPontuacaoRevenda === 1 && (
          <form
            id="formUm"
            onSubmit={pegarForm}
            className="form fundo-claro mb-4"
          >
            <label htmlFor="codigoInput" className="align-self-center">
              Digite o código / referência Integralmédica
            </label>
            <input
              type="text"
              name="codigoInput"
              value={codigo}
              onChange={(e) => setCodigo(e.target.value)}
              className="text-center"
              maxLength="30"
            />
            <label htmlFor="valorVenda" className="align-self-center">
              Digite o valor de venda do produto
            </label>
            <NumberFormat
              className="text-center"
              name="valorVenda"
              allowNegative={false}
              value={auxiliarValorUnitario}
              format={formatoMoeda}
              onValueChange={(values, sourceInfo) => {
                const { formattedValue, value } = values;
                setAuxiliarValorUnitario(value);
              }}
            />

            <Row className="d-flex justify-content-center align-items-center mb-2">
              {enviando ? (
                <button disabled className="btn-enviar" type="submit">
                  Enviando...
                </button>
              ) : (
                <button
                  className="btn-enviar"
                  style={{ height: 'auto' }}
                  type="submit"
                >
                  Adicionar produto
                </button>
              )}
            </Row>
          </form>
        )}

        {parceiroRedux?.tipoPontuacaoRevenda === 2 && (
          <form onSubmit={upload} className="form fundo-claro">
            <label htmlFor="arquivos">Foto</label>
            <input
              type="file"
              id="arquivos"
              name="arquivos"
              multiple
              style={{ display: 'none' }}
              onChange={(e) => {
                setArquivos(e.target.files);
                e.target.files.length > 0
                  ? setStatus(
                      `${e.target.files.length} arquivos(s) selecionado(s)`
                    )
                  : setStatus('Procurar...');
              }}
            />
            <input
              id="botao"
              type="button"
              value={status}
              onClick={(e) => {
                document.getElementById('arquivos').click();
              }}
            />
            <Row className="d-flex justify-content-center align-items-center">
              {enviando ? (
                <button disabled className="btn-enviar" type="submit">
                  ENVIANDO...
                </button>
              ) : (
                <button className="btn-enviar" type="submit">
                  ENVIAR
                </button>
              )}
            </Row>
          </form>
        )}

        <div>
          <div>
            {erroInput.erroSaldo && (
              <div className="d-flex flex-column align-items-center">
                <span
                  className="mb-2 align-self-center"
                  style={{ color: 'red' }}
                >
                  Você não possui saldo para esse produto
                </span>
              </div>
            )}
            {erroInput.erroDados && (
              <div className="d-flex flex-column align-items-center">
                <span
                  className="mb-2 align-self-center"
                  style={{ color: 'red' }}
                >
                  Preencha todos os dados e tente novamente
                </span>
              </div>
            )}
            {erroInput.erroProdutoInvalido && (
              <div className="d-flex flex-column align-items-center">
                <span
                  className="mb-2 align-self-center"
                  style={{ color: 'red' }}
                >
                  Produto não participante
                </span>
              </div>
            )}
            {parceiroRedux?.tipoPontuacaoRevenda === 1 && (
              <>
                <ul className="text-center" style={{ listStyle: 'none' }}>
                  {itens &&
                    itens.map((item, index) => (
                      <li key={item.id}>
                        {item.codigo} - {item.nome} - R$
                        {item.valorUnitario.toString().replace('.', ',')} -{' '}
                        {parseInt(item.valorPontos).toFixed(0)} pontos
                        <button onClick={delItem} className="btn-limpo ml-2">
                          <i id={index} className="fas fa-trash f-red"></i>
                        </button>
                      </li>
                    ))}
                </ul>
                <p className="mb-4 text-center">
                  Total:
                  {total !== 0 ? ` ${total} pontos` : ' 0'}
                </p>
                <form id="formDois" onSubmit={enviarVenda}>
                  <Row className="d-flex justify-content-center align-items-center">
                    <button className="btn-enviar" type="submit">
                      {enviando ? 'Enviando...' : 'Enviar'}
                    </button>
                  </Row>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}
