/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import './styles.css';
import { Row, Col, Container, Table } from 'react-bootstrap';
import api from '../../../services/API';

export default function Dashboard() {
  const [parceirosCadastrados, setParceirosCadastrados] = useState(0);
  const [revendasCadastradas, setRevendasCadastradas] = useState(0);
  const [parceirosPontuados, setParceirosPontuados] = useState(0);
  const [revendasPontuadas, setRevendasPontuadas] = useState(0);
  const [topParceirosPontuados, setTopParceirosPontuados] = useState([]);
  const [topRevendasPontuadas, setTopRevendasPontuadas] = useState([]);
  const [redesSelect, setRedesSelect] = useState([]);
  const [redeSelecionada, setRedeSelecionada] = useState([]);
  const [buscando, setBuscando] = useState(false);

  const buscarDados = async () => {
    setBuscando(true);
    const resultado = await api.get(`/api/dashboard?rede=${redeSelecionada}`);
    if (resultado) {
      setParceirosCadastrados(resultado.data.parceirosCadastrados);
      setRevendasCadastradas(resultado.data.revendasCadastradas);
      setParceirosPontuados(resultado.data.parceirosPontuados);
      setRevendasPontuadas(resultado.data.revendasPontuadas);
      setTopParceirosPontuados(resultado.data.topParceirosPontuados);
      setTopRevendasPontuadas(resultado.data.topRevendasPontuadas);
    }
    setBuscando(false);
  };

  const buscarRedes = async () => {
    const resultado = await api.get('/api/redes?ativo=1');
    if (resultado) {
      setRedesSelect(resultado.data.redes);
    }
  };

  useEffect(() => {
    buscarRedes();
    buscarDados();
  }, []);

  useEffect(() => {
    if (buscando) {
      setTopParceirosPontuados([]);
      setTopRevendasPontuadas([]);
    }
  }, [buscando]);

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Dashboard</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row>
        <Col xs={12} sm={6} md={4} lg={3} className="d-flex flex-column">
          <label htmlFor="redeSelecionada">Selecione uma Rede</label>
          <select
            className="input-select mb-3"
            name="redeSelecionada"
            id="redeSelecionada"
            onChange={(e) => setRedeSelecionada(e.target.value)}
            defaultValue=""
            placeholder=""
          >
            <option value="">Todas</option>
            {redesSelect.map((rede) => (
              <option value={rede.nome} key={rede.nome}>
                {rede.nome}
              </option>
            ))}
          </select>
        </Col>
        <Col
          xs={12}
          sm={1}
          className="d-flex flex-column justify-content-center align-items-center d-sm-block"
        >
          <button className="btn-busca" type="submit" onClick={buscarDados}>
            {buscando ? (
              <i className="fas fa-circle-notch fa-spin"></i>
            ) : (
              <i className="fas fa-search text-white"></i>
            )}
          </button>
        </Col>
      </Row>
      <Row>
        <Col className="mb-3" xs={12} md={6} lg={3}>
          <div className="card-dash">
            <span className="card-dash-titulo">
              Vendedores Cadastrados
            </span>
            <span className="card-dash-subtitulo">
              {buscando ? (
                <i className="fas fa-circle-notch fa-spin"></i>
              ) : (
                parceirosCadastrados
              )}
            </span>
            <div className="card-dash-footer"></div>
          </div>
        </Col>
        <Col className="mb-3" xs={12} md={6} lg={3}>
          <div className="card-dash">
            <span className="card-dash-titulo">
              Vendedores Pontuados
            </span>
            <span className="card-dash-subtitulo">
              {buscando ? (
                <i className="fas fa-circle-notch fa-spin"></i>
              ) : (
                parceirosPontuados
              )}
            </span>
            <div className="card-dash-footer"></div>
          </div>
        </Col>
        <Col className="mb-3" xs={12} md={6} lg={3}>
          <div className="card-dash">
            <span className="card-dash-titulo">Lojas Cadastradas</span>
            <span className="card-dash-subtitulo">
              {buscando ? (
                <i className="fas fa-circle-notch fa-spin"></i>
              ) : (
                revendasCadastradas
              )}
            </span>
            <div className="card-dash-footer"></div>
          </div>
        </Col>
        <Col className="mb-3" xs={12} md={6} lg={3}>
          <div className="card-dash">
            <span className="card-dash-titulo">Lojas Pontuadas</span>
            <span className="card-dash-subtitulo">
              {buscando ? (
                <i className="fas fa-circle-notch fa-spin"></i>
              ) : (
                revendasPontuadas
              )}
            </span>
            <div className="card-dash-footer"></div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="mb-3">
          <div className="dash-table-titulo">
            <h5 className="pl-3 mb-0 p-3">Top 10 Vendedores Pontuados</h5>
          </div>
          <Table className="dash-table" responsive variant="dark">
            <thead className="dash-table-header">
              <tr className="tr-gray">
                <th>Vendedor Loja</th>
                <th>Loja</th>
                <th>Pontos Acumulados</th>
                <th>Total Vendido</th>
              </tr>
            </thead>
            <tbody className="dash-table-body">
              {buscando && (
                <tr>
                  <td colSpan={4} className="text-center">
                    Carregando
                    <i className="fas fa-circle-notch fa-spin ml-3"></i>
                  </td>
                </tr>
              )}
              {topParceirosPontuados.map((parceiro) => (
                <tr key={parceiro.nome}>
                  <td>{parceiro.nome}</td>
                  <td>{parceiro.nomeFantasia}</td>
                  <td>
                    {Number(parceiro.totalPontos).toLocaleString('pt-BR')}
                  </td>
                  <td>
                    {Number(parceiro.totalVendido)
                      .toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })
                      .toString()
                      .replace(/\u00a0/g, ' ')}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col xs={12} md={6}>
          <div className="dash-table-titulo">
            <h5 className="pl-3 mb-0 p-3">Top 10 Lojas Pontuadas</h5>
          </div>
          <Table className="dash-table" responsive variant="dark">
            <thead className="dash-table-header">
              <tr className="tr-gray">
                <th>Loja</th>
                <th>Pontos Acumulados</th>
                <th>Total Vendido</th>
              </tr>
            </thead>
            <tbody className="dash-table-body">
              {buscando && (
                <tr>
                  <td colSpan={4} className="text-center">
                    Carregando
                    <i className="fas fa-circle-notch fa-spin ml-3"></i>
                  </td>
                </tr>
              )}
              {topRevendasPontuadas.map((revenda) => (
                <tr key={revenda.nomeFantasia}>
                  <td>{revenda.nomeFantasia}</td>
                  <td>{Number(revenda.totalPontos).toLocaleString('pt-BR')}</td>
                  <td>
                    {Number(revenda.totalVendido)
                      .toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })
                      .toString()
                      .replace(/\u00a0/g, ' ')}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
