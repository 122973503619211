import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/API';
import { useEffect } from 'react';

export default function UploadHistorico() {
  const [enviando, setEnviando] = useState(false);
  const [arquivo, setArquivo] = useState(false);
  const [status, setStatus] = useState('Procurar...');

  const uploadHistorico = async (e) => {
    e.preventDefault();

    if (!arquivo) {
      toast.error(`Selecione um arquivo primeiro`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    let formData = new FormData();
    formData.append('arquivo', arquivo);
    setEnviando(true);
    const resultado = await api.post('/api/upload/historico', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (resultado) {
      toast.success(
        `${Number(resultado.data.total).toLocaleString(
          'pt-BR'
        )} linhas de histórico processado`,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        }
      );
      setArquivo(null);
      setStatus('Procurar...');

      if (resultado.data.erros) {
        let divOculta = document.getElementById('erros');
        divOculta.className =
          'd-flex mt-3 mb-3 justify-content-center align-items-center';
        let html = '<ul>';
        resultado.data.erros.forEach((e) => {
          html += `<li>${e}</li>`;
        });
        html += '</ul>';
        divOculta.innerHTML = html;
      }
    }
    setEnviando(false);
  };

  useEffect(() => {
    if (!arquivo) {
      document.getElementById('arquivo').value = null;
    }
  }, [arquivo]);

  return (
    <Container fluid>
      <div>
        <h4 className="pt-3 pb-3 f-black">Upload Histórico</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <form className="form" onSubmit={uploadHistorico}>
          <label htmlFor="arquivo">Arquivo .CSV</label>
          <input
            type="file"
            id="arquivo"
            name="arquivo"
            style={{ display: 'none' }}
            onChange={(e) => {
              setArquivo(e.target.files[0]);
              e.target.files[0]
                ? setStatus(e.target.files[0].name)
                : setStatus('Procurar...');
            }}
          />
          <input
            id="botao"
            type="button"
            value={status}
            onClick={(e) => {
              document.getElementById('arquivo').click();
            }}
          />
          {enviando ? (
            <button disabled className="btn-primario" type="submit">
              PROCESSANDO...
            </button>
          ) : (
            <button className="btn-primario" type="submit">
              PROCESSAR
            </button>
          )}
          <div id="erros" className="d-none"></div>
        </form>
      </div>
    </Container>
  );
}
