import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import api from '../../../services/API';
import { toast } from 'react-toastify';
import historico from '../../../services/Historico';

export default function ModalAprovarResgate(props) {
  const [alterando, setAlterando] = useState(false);

  const aprovarResgate = async () => {
    setAlterando(true);
    const resultado = await api.post('/api/resgates/aprovar', {
      identificador: props.resgate,
    });

    if (resultado.status !== 200) {
      toast.error(resultado.data.mensagem, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
      props.onHide();
      historico.push('/painel/resgates');
    } else {
      toast.success(`Resgate aprovado com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      props.onHide();
      historico.push('/painel/resgates');
    }

    setAlterando(false);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Aprovar Resgate</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="d-flex justify-content-center align-items-center w-100">
          <p className="mt-3">Deseja aprovar o resgate?</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center w-100">
          {alterando ? (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={aprovarResgate}
              disabled
            >
              APROVANDO...
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={aprovarResgate}
            >
              APROVAR
            </button>
          )}
          <button
            type="button"
            className="btn btn-danger m-1"
            onClick={props.onHide}
          >
            CANCELAR
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
