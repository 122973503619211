import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import api from '../../../../services/API';
import { toast } from 'react-toastify';

export default function ModalEditar(props) {
  const [editando, setEditando] = useState(false);
  const [data, setData] = useState({});
  const [codigo, setCodigo] = useState('');
  const [nome, setNome] = useState('');
  const [valorPontos, setValorPontos] = useState('');

  const atualizar = async (e) => {
    e.preventDefault();

    if (!codigo) {
      toast.error('Código obrigatório', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    if (!nome) {
      toast.error('Nome obrigatório', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }
    if (!valorPontos) {
      toast.error('Valor Pontos obrigatório', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    const dataUpdated = {
      codigo,
      nome,
      valorPontos,
    };

    setEditando(true);
    const resultado = await api.put(`/api/produtos/${data.id}`, dataUpdated);

    if (resultado) {
      toast.success(`Produto alterado com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      props.onHide();
      props.atualizarProduto({ ...dataUpdated, id: data.id });
    }
    setEditando(false);
  };

  useEffect(() => {
    if (props.data) {
      setData(props.data);
      setCodigo(props.data.codigo);
      setValorPontos(props.data.valorPontos);
      setNome(props.data.nome);
    }
  }, [props]);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Editar</Modal.Title>
      </Modal.Header>
      <Modal.Body className="mt-3 mb-3">
        <div className="d-flex flex-column align-items-center">
          <form className="form" onSubmit={atualizar}>
            <label htmlFor="codigo">Código</label>
            <input
              id="codigo"
              name="codigo"
              type="text"
              placeholder="Código"
              value={codigo}
              onChange={(e) => setCodigo(e.target.value)}
            ></input>
            <label htmlFor="nome">Descrição</label>
            <input
              id="nome"
              name="nome"
              type="text"
              placeholder="Descrição"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            ></input>
            <label htmlFor="valorPontos">Valor em Pontos</label>
            <input
              id="valorPontos"
              name="valorPontos"
              type="number"
              step="0.01"
              placeholder="Valor em Pontos"
              value={valorPontos}
              onChange={(e) => setValorPontos(e.target.value)}
            ></input>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center w-100">
          {editando ? (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={atualizar}
              disabled
            >
              SALVANDO...
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={atualizar}
            >
              SALVAR
            </button>
          )}
          <button
            type="button"
            className="btn btn-danger m-1"
            onClick={props.onHide}
          >
            CANCELAR
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
