import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import { useSelector } from 'react-redux';

export default function Navbar() {
  const parceiro = useSelector((state) => {
    return state.parceiro;
  });

  const clearStorage = () => {
    const dataStorage = localStorage.getItem('integralmedica');

    if (dataStorage) {
      localStorage.removeItem('integralmedica');
    }
  };

  return (
    <nav className="nav w-100 d-flex justify-content-center align-items-center">
      <Link className="nav-link" to="/loja" onClick={clearStorage}>
        Home
      </Link>
      <Link className="nav-link" to="/perfil">
        Meu Perfil
      </Link>
      <Link className="nav-link" to="/pontuacoes">
        Pontuações
      </Link>
      <Link className="nav-link" to="/resgates">
        Resgates
      </Link>
      {/* <Link className="nav-link" to="/cartao">
        Cartão
  </Link> */}
      {parceiro.tipoPontuacaoRevenda ? (
        <Link className="nav-link" to="/enviar-pontuacao">
          Enviar Pontuação
        </Link>
      ) : null}
      <Link className="nav-link" to="/pontos-produtos">
        Pontos por Produto
      </Link>
      <Link className="nav-link" to="/regulamento">
        Regulamento
      </Link>
      <Link className="nav-link" to="/contato">
        Contato
      </Link>
    </nav>
  );
}
