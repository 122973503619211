import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import InputMask from 'react-input-mask';
import validarCpf from '../../../utils/validarCpf';
import historico from '../../../services/Historico';
import api from '../../../services/API';
export default function CadastrarVendedor(props) {
  const { idRevenda } = props.match.params;

  const [enviando, setEnviando] = useState(false);
  const [cpf, setCpf] = useState('');
  const [cnpjRevenda, setCnpjRevenda] = useState('');
  const [nome, setNome] = useState('');
  const [sexo, setSexo] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [celular, setCelular] = useState('');
  const [endRua, setRua] = useState('');
  const [endNumero, setNumero] = useState('');
  const [endComplemento, setComplemento] = useState('');
  const [endBairro, setBairro] = useState('');
  const [endCidade, setCidade] = useState('');
  const [endEstado, setEstado] = useState('');
  const [endCep, setCep] = useState('');
  const [hobby, setHobby] = useState('');
  const [revenda, setRevenda] = useState(null);
  const [senha, setSenha] = useState('');
  const [confirmarSenha, setConfirmarSenha] = useState('');
  const [aceitouPoliticaPrivacidade, setAceitouPoliticaPrivacidade] =
    useState(false);

  const validarCampos = (data) => {
    const entries = Object.entries(data);
    let temNulo = false;
    entries.forEach((param) => {
      if (temNulo) return;
      if (param[1] === '' && param[0] === 'sexo') {
        toast.error(`Sexo obrigatório`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
      if (
        (param[1] === '' && param[0] === 'aceitouPoliticaPrivacidade') ||
        (param[1] === false && param[0] === 'aceitouPoliticaPrivacidade')
      ) {
        toast.error(`Você deve aceitar a politica de privacidade`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
      if (
        param[1] === '' &&
        param[0] !== 'endComplemento' &&
        param[0] !== 'telefone' &&
        param[0] !== 'email'
      ) {
        const elemento = document.getElementsByName(param[0])[0];
        toast.error(`${elemento.placeholder} obrigatório`, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        temNulo = true;
        return;
      }
    });

    return temNulo;
  };

  const buscarCep = async (element) => {
    let input = element.target;
    let cep = input.value.replace(/[^\d]/g, '');
    if (!cep) return;
    const resultado = await axios.get(`https://viacep.com.br/ws/${cep}/json`);
    if (!resultado || resultado.data.erro) {
      toast.error('CEP inválido', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setCep('');
    } else {
      setRua(resultado.data.logradouro);
      setBairro(resultado.data.bairro);
      setCidade(resultado.data.localidade);
      setEstado(resultado.data.uf);
      setComplemento(resultado.data.complemento);
    }
  };

  const buscarCpf = async (cpf) => {
    cpf = cpf.replace(/[^\d]/g, '');
    if (!cpf) return;
    const resultado = await api.get(`/api/parceiros?cpf=${cpf}`);
    if (resultado.data.parceiros.length > 0) {
      toast.error('Vendedor Loja já cadastrado', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setCpf('');
    }
  };

  const buscarRevenda = async () => {
    const resultado = await api.get(`/api/revendas/${idRevenda}`);
    if (!resultado) {
      historico.push('/');
    } else {
      setRevenda(resultado.data);
    }
  };

  const validarBuscarCpf = (e) => {
    const cpf = e.target.value;
    if (!cpf) return;
    if (!validarCpf(cpf)) {
      toast.error('CPF não é válido', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setCpf('');
    } else {
      buscarCpf(cpf);
    }
  };

  const validarTelefoneCelular = async (e) => {
    const valor = e.target.value.replace(/[^\d]/g, '');
    if (!valor) return;
    if (valor.length < 10) {
      toast.error(`${e.target.placeholder} inválido`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      e.target.placeholder === 'Telefone' ? setTelefone('') : setCelular('');
    }
  };

  const validarDataNascimento = async (e) => {
    const data = e.target.value.replace(/[^\d]/g, '');
    if (!data) return;
    if (data.length < 8) {
      toast.error(`${e.target.placeholder} inválido`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setDataNascimento('');
    }
  };

  const cadastrarParceiro = async (e) => {
    e.preventDefault();
    const data = {
      idRevenda: idRevenda,
      cnpjRevenda: cnpjRevenda.value,
      nome,
      cpf,
      telefone,
      celular,
      email,
      dataNascimento,
      sexo,
      endRua,
      endNumero,
      endComplemento,
      endBairro,
      endCidade,
      endEstado,
      endCep,
      hobby,
      senha,
      aceitouPoliticaPrivacidade,
      senha,
      confirmarSenha,
    };

    const temNulo = validarCampos(data);
    if (temNulo) {
      return;
    }

    if (senha !== confirmarSenha) {
      toast.error(`Senhas não conferem`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    data.endCep = data.endCep.replace(/[^\d]/g, '');
    if (data.cnpjRevenda) {
      data.cnpjRevenda = data.cnpjRevenda.replace(/[^\d]/g, '');
    }

    data.telefone = data.telefone.replace(/[^\d]/g, '');
    data.celular = data.celular.replace(/[^\d]/g, '');
    data.cpf = data.cpf.replace(/[^\d]/g, '');
    data.dataNascimento = data.dataNascimento.replace(/[^\d]/g, '');
    data.dataNascimento = `${data.dataNascimento.substring(
      4
    )}${data.dataNascimento.substring(2, 4)}${data.dataNascimento.substring(
      0,
      2
    )}`;

    setEnviando(true);
    const resultado = await api.post('/api/parceiros', data);

    if (resultado) {
      toast.success('Cadastro realizado com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      historico.push('/loja');
    }
    setEnviando(false);
  };

  useEffect(() => {
    if (idRevenda) {
      buscarRevenda();
    }
  }, [idRevenda]);

  return (
    <Container className="mt-5 mb-5">
      <div>
        <h4 className="pt-3 pb-3 f-black">{`Cadastrar Vendedor - ${
          revenda && revenda.nomeFantasia
        }`}</h4>
        <hr className="hr-loja mt-0 mb-5" />
      </div>
      <div className="d-flex flex-column align-items-center">
        <form className="form" onSubmit={cadastrarParceiro}>
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="cpf">CPF</label>
              <InputMask
                id="cpf"
                name="cpf"
                type="text"
                placeholder="CPF"
                value={cpf}
                mask="999.999.999-99"
                onChange={(e) => setCpf(e.target.value)}
                onBlur={validarBuscarCpf}
              ></InputMask>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="dataNascimento">Data de Nascimento</label>
              <InputMask
                id="dataNascimento"
                name="dataNascimento"
                type="text"
                placeholder="Data de Nascimento"
                value={dataNascimento}
                onBlur={validarDataNascimento}
                onChange={(e) => setDataNascimento(e.target.value)}
                mask="99/99/9999"
              ></InputMask>
            </Col>
          </Row>

          <label htmlFor="nome">Nome</label>
          <input
            id="nome"
            name="nome"
            type="text"
            placeholder="Nome"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          ></input>
          <label htmlFor="nome">Email</label>
          <input
            id="email"
            name="email"
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></input>
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="celular">Celular</label>
              <InputMask
                id="celular"
                name="celular"
                type="text"
                placeholder="Celular"
                value={celular}
                mask="(99) 99999-9999"
                onChange={(e) => setCelular(e.target.value)}
                onBlur={validarTelefoneCelular}
              ></InputMask>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="telefone">Telefone</label>
              <InputMask
                id="telefone"
                name="telefone"
                type="text"
                placeholder="Telefone"
                value={telefone}
                mask="(99) 9999-9999"
                onChange={(e) => setTelefone(e.target.value)}
                onBlur={validarTelefoneCelular}
              ></InputMask>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="sexo">Sexo</label>
              <select
                id="sexo"
                name="sexo"
                value={sexo}
                onChange={(e) => setSexo(e.target.value)}
                placeholder="Sexo"
              >
                <option disabled value="">
                  Selecione o sexo
                </option>
                <option value="F">Feminino</option>
                <option value="M">Masculino</option>
              </select>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="endCep">CEP</label>
              <InputMask
                id="endCep"
                name="endCep"
                type="text"
                placeholder="CEP"
                mask="99999-999"
                onBlur={buscarCep}
                value={endCep}
                onChange={(e) => setCep(e.target.value)}
              ></InputMask>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={9}>
              <label htmlFor="endRua">Endereço</label>
              <input
                id="endRua"
                name="endRua"
                type="text"
                placeholder="Endereço"
                value={endRua}
                onChange={(e) => setRua(e.target.value)}
              ></input>
            </Col>

            <Col className="d-flex flex-column" xs={12} sm={3}>
              <label htmlFor="endNumero">Número</label>
              <input
                id="endNumero"
                name="endNumero"
                type="text"
                placeholder="Número"
                value={endNumero}
                onChange={(e) => setNumero(e.target.value)}
              ></input>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="endBairro">Bairro</label>
              <input
                id="endBairro"
                name="endBairro"
                type="text"
                placeholder="Bairro"
                value={endBairro}
                onChange={(e) => setBairro(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="endComplemento">Complemento</label>
              <input
                id="endComplemento"
                name="endComplemento"
                type="text"
                placeholder="Complemento"
                value={endComplemento}
                onChange={(e) => setComplemento(e.target.value)}
              ></input>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="endCidade">Cidade</label>
              <input
                id="endCidade"
                name="endCidade"
                type="text"
                placeholder="Cidade"
                value={endCidade}
                onChange={(e) => setCidade(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="endEstado">Estado</label>
              <input
                id="endEstado"
                name="endEstado"
                type="text"
                placeholder="Estado"
                value={endEstado}
                onChange={(e) => setEstado(e.target.value)}
                maxLength={2}
              ></input>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column" xs={12}>
              <label htmlFor="hobby">Qual seu hobby favorito?</label>
              <input
                id="hobby"
                name="hobby"
                type="text"
                placeholder="Hobbie"
                value={hobby}
                onChange={(e) => setHobby(e.target.value)}
              ></input>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="senha">Senha</label>
              <input
                id="senha"
                name="senha"
                type="password"
                placeholder="Senha"
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
              ></input>
            </Col>
            <Col className="d-flex flex-column" xs={12} sm={6}>
              <label htmlFor="confirmarSenha">Confirmar Senha</label>
              <input
                id="confirmarSenha"
                name="confirmarSenha"
                type="password"
                placeholder="Confirmar Senha"
                value={confirmarSenha}
                onChange={(e) => setConfirmarSenha(e.target.value)}
              ></input>
            </Col>
          </Row>
          <Row className="mt-3 mb-3">
            <Col
              className="d-flex flex-row justify-content-center align-items-center"
              xs={12}
            >
              <input
                className="m-0 mr-3"
                id="aceitouPoliticaPrivacidade"
                name="aceitouPoliticaPrivacidade"
                type="checkbox"
                value={aceitouPoliticaPrivacidade}
                onChange={(e) =>
                  setAceitouPoliticaPrivacidade(e.target.checked)
                }
              ></input>
              <label className="f-14 mb-0" htmlFor="aceitouPoliticaPrivacidade">
                Aceito a{' '}
                <a href="/politica-privacidade" target="_blank">
                  política de privacidade
                </a>{' '}
                do Programa Integralmédica
              </label>
            </Col>
          </Row>
          {enviando ? (
            <button disabled className="btn-primario" type="submit">
              CADASTRANDO...
            </button>
          ) : (
            <button className="btn-primario" type="submit">
              CADASTRAR
            </button>
          )}
        </form>
      </div>
    </Container>
  );
}
