import { takeLatest, call, put, all } from 'redux-saga/effects';
import api from '../../../services/API';
import {
  atualizarPerfilSucesso,
  atualizarPerfilFalhou,
  atualizarSaldoSucesso,
  atualizarSaldoFalhou,
  resgatarPremioSucesso,
  resgatarPremioFalhou,
  aceitarRegulamento,
  alterarStatusCartao,
} from './actions';

import { toast } from 'react-toastify';
import historico from '../../../services/Historico';

export function* atualizarPerfil({ payload }) {
  try {
    const response = yield call(api.put, '/api/parceiros', payload);

    yield put(atualizarPerfilSucesso(response.data));
    toast.success('Dados atualizado com sucesso', {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 10000,
    });
  } catch (err) {
    yield put(atualizarPerfilFalhou());
  }
}

export function* atualizarSaldo({ payload }) {
  try {
    const response = yield call(api.get, `/api/pontuacoes/saldo/${payload}`);

    let saldo = Number(response.data[0].saldoPontos)

    yield put(atualizarSaldoSucesso(saldo));
  } catch (err) {
    yield put(atualizarSaldoFalhou());
  }
}

export function* resgatarPremio({ payload }) {
  const { codigoPremio, codigoVariante, qtde, obs, idLoja, enderecoEntrega } =
    payload;

  try {
    const response = yield call(api.post, '/api/resgatar', {
      idLoja,
      codigoVariante,
      codigoPremio,
      qtde,
      obs,
      enderecoEntrega,
    });

    yield put(resgatarPremioSucesso(response.data));
    toast.success('Resgate realizado com sucesso!', {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 10000,
    });
    historico.push('/resgates');
  } catch (err) {
    yield put(resgatarPremioFalhou());
  }
}

export function* aceitouRegulamento() {
  yield call(api.put, '/api/parceiros', { aceitouRegulamento: 1 });
  put(aceitarRegulamento());
}

export function statusCartao({ payload }) {
  put(alterarStatusCartao(payload));
}

export default all([
  takeLatest('@parceiro/ATUALIZAR_PERFIL', atualizarPerfil),
  takeLatest('@parceiro/ATUALIZAR_SALDO', atualizarSaldo),
  takeLatest('@parceiro/RESGATAR_PREMIO', resgatarPremio),
  takeLatest('@parceiro/ACEITAR_REGULAMENTO', aceitouRegulamento),
  takeLatest('@parceiro/ALTERAR_STATUS_CARTAO', statusCartao),
]);
