/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container, Card } from 'react-bootstrap';
import api from '../../../services/API';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment-timezone';
import './styles.css';
import ModalMidia from '../ModalMidia';
import ModalExcluir from './ModalExcluir';

export default function Midias() {
  const [mostrarModalMidia, setMostrarModalMidia] = useState(false);
  const [arquivo, setArquivo] = useState('');
  const [tipo, setTipo] = useState('');
  const [redesSelect, setRedesSelect] = useState([]);
  const [redeSelecionada, setRedeSelecionada] = useState([]);
  const [dataInicial, setDataInicial] = useState(
    moment().subtract(7, 'days').toISOString().substr(0, 10)
  );
  const [dataFinal, setDataFinal] = useState(
    moment().toISOString().substr(0, 10)
  );
  const [data, setData] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const [id, setId] = useState('');
  const [mostrarModalExcluir, setMostrarModalExcluir] = useState(false);

  useEffect(() => {
    buscarRedes();
    buscarMidias();
  }, []);

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  const buscarRedes = async () => {
    const resultado = await api.get('/api/redes?ativo=1');
    if (resultado) {
      setRedesSelect(resultado.data.redes);
    }
  };

  const buscarMidias = async () => {
    setBuscando(true);
    const resultado = await api.get(
      `/api/resgates/fotos_videos?rede=${redeSelecionada}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`
    );
    if (resultado) {
      setData(resultado.data.midias);
    }
    setBuscando(false);
  };

  const fecharModalMidia = () => {
    setMostrarModalMidia(false);
  };

  const abrirModalExcluir = (id) => {
    setId(id);
    setMostrarModalExcluir(true);
  };

  const fecharModalExcluir = () => {
    setId('');
    setMostrarModalExcluir(false);
  };

  const atualizarExclusao = (id) => {
    setData(data.filter((d) => d.midia.id !== id));
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Fotos e Vídeos</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row>
        <Col xs={12} sm={12} md={4} lg={3} className="d-flex flex-column">
          <label htmlFor="redeSelecionada">Selecione uma Rede</label>
          <select
            className="input-select mb-3"
            name="redeSelecionada"
            id="redeSelecionada"
            onChange={(e) => setRedeSelecionada(e.target.value)}
            defaultValue=""
            placeholder=""
          >
            <option value="">Todas</option>
            {redesSelect.map((rede) => (
              <option value={rede.nome} key={rede.nome}>
                {rede.nome}
              </option>
            ))}
          </select>
        </Col>
        <Col xs={12} sm={6} md={3} lg={2} className="d-flex flex-column">
          <label>De</label>
          <input
            type="date"
            className="input-theme mb-3"
            value={dataInicial}
            onChange={(e) => setDataInicial(e.target.value)}
            max="2999-12-31"
          ></input>
        </Col>
        <Col xs={12} sm={6} md={3} lg={2} className="d-flex flex-column">
          <label>Até</label>
          <input
            type="date"
            className="input-theme mb-3"
            onChange={(e) => setDataFinal(e.target.value)}
            value={dataFinal}
            max="2999-12-31"
          ></input>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={1}
          className="d-flex flex-column justify-content-center align-items-center d-md-block"
        >
          <button className="btn-busca" type="submit" onClick={buscarMidias}>
            {buscando ? (
              <i className="fas fa-circle-notch fa-spin"></i>
            ) : (
              <i className="fas fa-search"></i>
            )}
          </button>
        </Col>
      </Row>
      <Row>
        {data.length === 0 && (
          <Col xs={12}>
            <Card className="border-0 p-3">
              {buscando ? (
                <span>
                  Carregando
                  <i className="fas fa-circle-notch fa-spin ml-3"></i>
                </span>
              ) : (
                <span>Sem resultados</span>
              )}
            </Card>
          </Col>
        )}
        {data.length > 0 &&
          data.map((midia, idx) => (
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={3}
              className="d-flex flex-column text-center"
              key={idx}
            >
              <div className="card-midia">
                <div className="card-imagem">
                  <button
                    className="btn-limpo w-100 h-100"
                    onClick={(e) => {
                      setArquivo(midia.midia.url);
                      setTipo(midia.midia.tipo);
                      setMostrarModalMidia(true);
                    }}
                  >
                    {midia.midia.tipo.includes('image') ? (
                      <img
                        alt="Foto"
                        src={`${process.env.REACT_APP_API_BASE_URL}/arquivos/${midia.midia.url}`}
                      ></img>
                    ) : (
                      <video
                        src={`${process.env.REACT_APP_API_BASE_URL}/arquivos/${midia.midia.url}`}
                      ></video>
                    )}
                  </button>
                </div>
                <div className="card-infos">
                  <span className="card-midia-titulo">{`${midia.id} - ${midia.parceiro} - ${midia.premio}`}</span>
                  <span className="card-midia-subtitulo">{`${midia.revenda} - ${midia.cidade}`}</span>
                  <span className="card-midia-subtitulo">
                    {moment(midia.resgatadoEm).format('DD/MM/YYYY')}
                  </span>
                </div>
                <div className="card-links">
                  <a
                    href={`https://api.whatsapp.com/send?text=Resgate%20do%20${midia.parceiro}%20da%20revenda%20${midia.revenda}%20${process.env.REACT_APP_API_BASE_URL}/arquivos/${midia.midia.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="fa-stack fa-lg">
                      <i className="fa fa-circle fa-stack-2x"></i>
                      <i className="fab fa-whatsapp fa-stack-1x fa-inverse"></i>
                    </span>
                  </a>
                  <a
                    href={`mailto:?body=Resgate%20do%20${midia.parceiro}%20da%20revenda%20${midia.revenda}%20${process.env.REACT_APP_API_BASE_URL}/arquivos/${midia.midia.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="fa-stack fa-lg">
                      <i className="fa fa-circle fa-stack-2x"></i>
                      <i className="fa fa-envelope fa-stack-1x fa-inverse"></i>
                    </span>
                  </a>
                  <a
                    href={`${process.env.REACT_APP_API_BASE_URL}/arquivos/${midia.midia.url}`}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="fa-stack fa-lg">
                      <i className="fa fa-circle fa-stack-2x"></i>
                      <i className="fa fa-arrow-down fa-stack-1x fa-inverse"></i>
                    </span>
                  </a>
                  <button
                    className="btn-limpo"
                    onClick={(e) => abrirModalExcluir(midia.midia.id)}
                  >
                    <span className="fa-stack fa-lg f-red">
                      <i className="fa fa-circle fa-stack-2x"></i>
                      <i className="fa fa-trash fa-stack-1x fa-inverse"></i>
                    </span>
                  </button>
                </div>
              </div>
            </Col>
          ))}
      </Row>
      <ModalMidia
        show={mostrarModalMidia}
        onHide={fecharModalMidia}
        diretorioArquivo={arquivo}
        tipo={tipo}
      />
      <ModalExcluir
        show={mostrarModalExcluir}
        onHide={fecharModalExcluir}
        id={id}
        atualizarExclusao={atualizarExclusao}
      />
    </Container>
  );
}
