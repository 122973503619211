import React from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import api from '../../../../services/API';

export default function ModalObsEdit(props) {
  const updateObs = async (data) => {
    const result = await api.put(
      `/api/resgates/${props.resgate}/alterar-observacao`,

      data
    );

    props.onHide({ id: props.resgate, obs: props.obs });
  };

  return (
    <Modal
      show={props.open}
      onHide={() => {
        props.onHide();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Alterar observação</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup>
          <Form.Control
            value={props.obs}
            onChange={(e) => props.setObs(e.target.value)}
          />
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            props.onHide();
          }}
        >
          Fechar
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            updateObs({ descricao: props.obs });
          }}
        >
          Alterar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
