/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container, Row, Col } from 'react-bootstrap';
import api from '../../../services/API';
import ModalObsMensuracao from '../ModalObsMensuracao/ModalObsMensuracao';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import moment from 'moment-timezone';
import sortFunc from '../../../utils/ordernarColunaInt';

const { SearchBar } = Search;

const defaultSorted = [
  {
    dataField: 'resultadoMensal',
    order: 'desc',
  },
];

export default function Mensuracao() {
  const columns = [
    {
      dataField: 'rede',
      text: 'Rede',
      sort: true,
    },
    {
      dataField: 'mesesAtendidosAntes',
      text: 'Meses Atendidos Antes',
      sort: true,
      hidden: true,
    },
    {
      dataField: 'mediaMensal',
      text: 'Média Mensal',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.mediaMensal)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'mediaMensalPos',
      text: 'Média Mensal Pós',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.mediaMensalPos)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'resultadoMensal',
      text: 'Resultado Mensal',
      sort: true,
      formatter: (celula, valor) => {
        return `${Number(valor.resultadoMensal).toLocaleString('pt-BR')}%`;
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'mediaSemanal',
      text: 'Média Semanal',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.mediaSemanal)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'mediaSemanalPos',
      text: 'Média Semanal Pós',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.mediaSemanalPos)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'resultadoSemanal',
      text: 'Resultado Semanal',
      sort: true,
      formatter: (celula, valor) => {
        return `${Number(valor.resultadoSemanal).toLocaleString('pt-BR')}%`;
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'totalVendidoPos',
      text: 'Total Vendido Pós',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.totalVendidoPos)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'mesesAtendidosPos',
      text: 'Meses Atendidos Pós',
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'dataCadastro',
      text: 'Data de Cadastro',
      sort: true,
      formatter: (celula, valor) => {
        return valor.dataCadastro && valor.dataCadastro !== 0
          ? moment(valor.dataCadastro).format('DD/MM/YYYY HH:mm:ss')
          : '';
      },
    },
    {
      dataField: 'observacao',
      text: 'Observação',
      sort: true,
    },
    {
      dataField: 'editarObs',
      isDummyField: true,
      text: 'Editar Observação',
      sort: true,
      formatter: (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => {
            abrirModalObs(valor.rede, valor.observacao);
          }}
        >
          <i className="fas fa-edit"></i>
        </button>
      ),
    },
  ];

  const [mostrarModalObs, setMostrarModalObs] = useState(false);
  const [rede, setRede] = useState('');
  const [observacao, setObservacao] = useState('');
  const [data, setData] = useState([]);
  const [redesSelect, setRedesSelect] = useState([]);
  const [redeSelecionada, setRedeSelecionada] = useState([]);
  const [buscando, setBuscando] = useState(false);

  useEffect(() => {
    buscarRedes();
    buscarMensuracao();
  }, []);

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  const buscarMensuracao = async () => {
    setBuscando(true);
    const resultado = await api.get(`/api/mensuracao?rede=${redeSelecionada}`);
    if (resultado) {
      setData(resultado.data.mensuracao);
    }
    setBuscando(false);
  };

  const buscarRedes = async () => {
    const resultado = await api.get('/api/redes?ativo=1');
    if (resultado) {
      setRedesSelect(resultado.data.redes);
    }
  };

  const abrirModalObs = (rede, observacao) => {
    setRede(rede);
    setObservacao(observacao);
    setMostrarModalObs(true);
  };

  const fecharModalObs = () => {
    setMostrarModalObs(false);
    setRede('');
    setObservacao('');
  };

  const atualizarObsTabela = (obs, rede) => {
    const updated = data.map((row) => {
      if (row.rede === rede) {
        row.observacao = obs;
      }
      return row;
    });

    setData(updated);
  };

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Mensuração</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row>
        <Col xs={12} sm={6} md={4} lg={3} className="d-flex flex-column">
          <label htmlFor="redeSelecionada">Selecione uma Rede</label>
          <select
            className="input-select mb-3"
            name="redeSelecionada"
            id="redeSelecionada"
            onChange={(e) => setRedeSelecionada(e.target.value)}
            defaultValue=""
            placeholder=""
          >
            <option value="">Todas</option>
            {redesSelect.map((rede) => (
              <option value={rede.nome} key={rede.nome}>
                {rede.nome}
              </option>
            ))}
          </select>
        </Col>
        <Col
          xs={12}
          sm={1}
          className="d-flex flex-column justify-content-center align-items-center d-sm-block"
        >
          <button
            className="btn-busca"
            type="submit"
            onClick={buscarMensuracao}
          >
            {buscando ? (
              <i className="fas fa-circle-notch fa-spin"></i>
            ) : (
              <i className="fas fa-search"></i>
            )}
          </button>
        </Col>
      </Row>
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="rede"
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse">
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar"
                  className="input-theme"
                />
              </div>
              <BootstrapTable
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory(options)}
                defaultSorted={defaultSorted}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>

      <ModalObsMensuracao
        show={mostrarModalObs}
        onHide={fecharModalObs}
        rede={rede}
        observacao={observacao}
        atualizarObsTabela={atualizarObsTabela}
      />
    </Container>
  );
}
