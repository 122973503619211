import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import api from '../../../../services/API';
import { toast } from 'react-toastify';
import { Form, Input } from '@rocketseat/unform';

export default function ModalEditar(props) {
  const [editando, setEditando] = useState(false);
  const [data, setData] = useState({});
  const [descricao, setDescricao] = useState('');
  const [nome, setNome] = useState('');
  const [codigo, setCodigo] = useState('');
  const [custoTotal, setCustoTotal] = useState('');
  const [tipo, setTipo] = useState('');
  const [foto, setFoto] = useState(null);
  const [preview, setPreview] = useState(null);
  const [status, setStatus] = useState('Procurar...');
  const [categorias, setCategorias] = useState([]);
  const [subCategorias, setSubCategorias] = useState([]);
  const [categoriaId, setCategoriaId] = useState('');
  const [categoriaLevel, setCategoriaLevel] = useState('');
  const [showSubCategorias, setShowSubCategorias] = useState(false);
  const [categoriaIdPai, setCategoriaIdPai] = useState('');

  const alterarFoto = async (e) => {
    if (e.target.files.length > 0) {
      setStatus(`${e.target.files[0].name}`);
      setFoto(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
    } else {
      setStatus('Procurar...');
      setPreview(null);
    }
  };

  const buscarCategorias = async () => {
    try {
      const response = await api.get('/api/premios/categorias');
      setCategorias(response.data.categorias.filter(cat => 
        cat.id !== 166 && 
        cat.id !== 2579 && 
        cat.id !== 1942 && 
        cat.id !== 1484 && 
        cat.id !== 2563
      ));
    } catch (error) {
      console.log('Erro ao buscar as categorias', error);
    }
  };

  const buscarSubCategorias = async (idCategoriaPai) => {
    if (idCategoriaPai) {
      try {
        const response = await api.get(`/api/premios/categorias?level=2&idCategoriaPai=${idCategoriaPai}`);
        
        const subCats = response.data.categorias.filter(cat => cat.id !== 2552);
        setSubCategorias(subCats);
        setShowSubCategorias(true);
      } catch (error) {
        console.log('Erro ao buscar as subcategorias', error);
        setSubCategorias([]);
        setShowSubCategorias(false);
      }
    } else {
      setSubCategorias([]);
      setShowSubCategorias(false);
    }
  };

  const handleCategoriaChange = async (e) => {
    const selectedValue = e.target.value;
    const selectedCategoria = categorias.find(cat => cat.idCategoria === selectedValue);
    
    setCategoriaIdPai(selectedValue);
    setCategoriaId(selectedValue);
    setCategoriaLevel('1');
    
    if (selectedValue) {
      await buscarSubCategorias(selectedValue);
      setShowSubCategorias(true);
    } else {
      setSubCategorias([]);
      setShowSubCategorias(false);
    }
  };

  const handleSubCategoriaChange = (e) => {
    const selectedValue = e.target.value;
    
    if (selectedValue) {
      setCategoriaId(selectedValue);
      setCategoriaLevel('2');
    } else {
      setCategoriaId(categoriaIdPai);
      setCategoriaLevel('1');
    }
  };

  const atualizar = async (e) => {
    e.preventDefault();
    if (!nome) {
      toast.error('Informe o nome', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!codigo) {
      toast.error('Informe o código', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!descricao) {
      toast.error('Informe a descrição', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!custoTotal) {
      toast.error('Informe o custo total', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!tipo) {
      toast.error('Informe o tipo', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    if (!categoriaId) {
      toast.error('Selecione uma categoria', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    setEditando(true);

    let formData = new FormData();
    formData.append('nome', nome);
    formData.append('descricao', descricao);
    formData.append('codigo', codigo);
    formData.append('custoTotal', custoTotal);
    formData.append('tipo', tipo);
    formData.append('foto', foto);
    formData.append('codigoCategoria', categoriaId);
    formData.append('levelCategoria', categoriaLevel);

    try {
      const resultado = await api.put(`/api/premios/${data.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (resultado.data) {
        setData(resultado.data);
        setCustoTotal(resultado.data.custoTotal);
        
        toast.success('Prêmio atualizado com sucesso', {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 10000,
        });
        
        props.atualizarPremio(resultado.data);
        props.onHide();
      }
    } catch (error) {
      toast.error('Erro ao atualizar prêmio', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      console.error('Erro ao atualizar:', error);
    } finally {
      setEditando(false);
    }
  };

  useEffect(() => {
    if (props.data) {
      setData(props.data);
      setNome(props.data.nome);
      setDescricao(props.data.descricao);
      setCodigo(props.data.codigo);
      setCustoTotal(props.data.custoTotal);
      setTipo(props.data.tipo || 'geral');
      setPreview(props.data.diretorioFoto);
      setStatus('Procurar...');
      
      const carregarCategorias = async () => {
        try {
          const responseCategorias = await api.get('/api/premios/categorias?level=1');
          
          const categoriasFiltered = responseCategorias.data.categorias.filter(cat => 
            cat.id !== 166 && 
            cat.id !== 2579 && 
            cat.id !== 1942 && 
            cat.id !== 1484 && 
            cat.id !== 2563
          );
          setCategorias(categoriasFiltered);
          
          if (props.data.codigoCategoria) {
            const responseCategoria = await api.get(`/api/premios/categorias?idCategoria=${props.data.codigoCategoria}`);
            
            const categoriaAtual = responseCategoria.data.categorias[0];
            
            if (categoriaAtual) {
              if (props.data.levelCategoria === '2') {
                const responsePai = await api.get(`/api/premios/categorias?idCategoria=${categoriaAtual.idCategoriaPai}`);
                const categoriaPai = responsePai.data.categorias[0];
                
                if (categoriaPai) {
                  setCategoriaIdPai(categoriaPai.idCategoria);
                  setCategoriaId(props.data.codigoCategoria);
                  setCategoriaLevel('2');
                  
                  const responseSubcats = await api.get(`/api/premios/categorias?level=2&idCategoriaPai=${categoriaPai.idCategoria}`);
                  
                  setSubCategorias(responseSubcats.data.categorias.filter(cat => cat.id !== 2552));
                  setShowSubCategorias(true);
                }
              } else {
                setCategoriaIdPai(props.data.codigoCategoria);
                setCategoriaId(props.data.codigoCategoria);
                setCategoriaLevel('1');
              }
            }
          } else {
            setCategoriaId('');
            setCategoriaIdPai('');
            setCategoriaLevel('');
            setShowSubCategorias(false);
            setSubCategorias([]);
          }
        } catch (error) {
          console.error('Erro ao carregar categorias:', error);
        }
      };

      carregarCategorias();
    }
  }, [props.data]);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Editar</Modal.Title>
      </Modal.Header>
      <Modal.Body className="mt-3 mb-3">
        <div className="d-flex flex-column align-items-center">
          <Form className="form" onSubmit={atualizar}>
            <label htmlFor="foto">Foto</label>
            <input
              type="file"
              id="foto"
              name="foto"
              style={{ display: 'none' }}
              onChange={alterarFoto}
            />
            <input
              className="text-left"
              id="botao"
              type="button"
              value={status}
              onClick={(e) => {
                document.getElementById('foto').click();
              }}
            />
            {preview && (
              <img alt="Preview" src={preview} className="mt-3 mb-3 w-100" />
            )}
            <label htmlFor="nome">Código</label>
            <Input
              id="codigo"
              name="codigo"
              type="text"
              placeholder="Código"
              value={codigo}
              onChange={(e) => setCodigo(e.target.value)}
            ></Input>
            <label htmlFor="nome">Nome</label>
            <Input
              id="nome"
              name="nome"
              type="text"
              placeholder="Nome"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            ></Input>
            <label htmlFor="descricao">Descrição</label>
            <textarea
              id="descricao"
              name="descricao"
              placeholder="Descrição"
              className="premio-desc p-2"
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
            ></textarea>
            <label htmlFor="custoTotal">Custo Total</label>
            <Input
              id="custoTotal"
              name="custoTotal"
              type="number"
              step="0.01"
              placeholder="Custo Total"
              value={custoTotal}
              onChange={(e) => setCustoTotal(e.target.value)}
            ></Input>
            <label htmlFor="tipo">Tipo</label>
            <select
              id="tipo"
              name="tipo"
              placeholder="Tipo"
              value={tipo}
              onChange={(e) => setTipo(e.target.value)}
            >
              <option disabled value="">
                Selecione o tipo
              </option>
              <option value="geral">Geral</option>
              <option value="bivolt">Bivolt</option>
              <option value="credito">Crédito</option>
              <option value="recarga">Recarga</option>
            </select>
            <label htmlFor="categoria">Categoria</label>
            <select
              id="categoria"
              name="categoria"
              placeholder="Categoria"
              value={categoriaIdPai}
              onChange={handleCategoriaChange}
            >
              <option value="">Selecione a categoria</option>
              {categorias.map((categoria) => (
                <option 
                  key={categoria.id} 
                  value={categoria.idCategoria}
                >
                  {categoria.nome} ({categoria.idCategoria})
                </option>
              ))}
            </select>

            {showSubCategorias && (
              <>
                <label htmlFor="subcategoria">Sub-categoria</label>
                <select
                  id="subcategoria"
                  name="subcategoria"
                  value={categoriaId}
                  onChange={handleSubCategoriaChange}
                >
                  <option value="">Selecione a sub-categoria</option>
                  {subCategorias.map((subcategoria) => (
                    <option 
                      key={subcategoria.id} 
                      value={subcategoria.idCategoria}
                    >
                      {subcategoria.nome} ({subcategoria.idCategoria})
                    </option>
                  ))}
                </select>
              </>
            )}
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center w-100">
          {editando ? (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={atualizar}
              disabled
            >
              SALVANDO...
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={atualizar}
            >
              SALVAR
            </button>
          )}
          <button
            type="button"
            className="btn btn-danger m-1"
            onClick={props.onHide}
          >
            CANCELAR
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
