/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Modal, Card } from 'react-bootstrap';
import api from '../../../services/API';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import moment from 'moment-timezone';

export default function ModalObsMensuracao(props) {
  const [alterando, setAlterando] = useState(false);
  const [buscando, setBuscando] = useState(false);
  const [obs, setObs] = useState();
  const [observacoes, setObservacoes] = useState([]);

  const alterarObs = async () => {
    setAlterando(true);
    const resultado = await api.post('/api/mensuracao/observacao', {
      rede: props.rede,
      obs: obs,
    });

    if (resultado) {
      props.atualizarObsTabela(obs, props.rede);
      toast.success(`Observação salva com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      props.onHide();
    }
    setAlterando(false);
  };

  const buscarObservacoes = async () => {
    setBuscando(true);
    const resultado = await api.get(
      `api/mensuracao/observacoes?rede=${props.rede}`
    );
    setObservacoes(resultado.data.observacoes);
    setBuscando(false);
  };

  useEffect(() => {
    (props.observacao === '' || props.observacao) && setObs(props.observacao);

    props.rede && buscarObservacoes();
  }, [props]);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Editar Observação</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-2">
        <form className="form mw-100 d-flex justify-content-center align-items-center">
          <textarea
            id="obs"
            className="w-100 textarea"
            value={obs}
            onChange={(e) => setObs(e.target.value)}
          />
        </form>
        <div className="mt-3">
          {buscando && (
            <Card className="mb-3 mt-3">
              <Card.Header>Buscando...</Card.Header>
              <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                <i className="fas fa-circle-notch fa-spin mt-2 mb-2"></i>
              </Card.Body>
            </Card>
          )}
          {!buscando &&
            observacoes.map((obs) => (
              <Card className="mb-3 mt-3">
                <Card.Header>
                  Editado por {obs.nome} em{' '}
                  {moment(obs.criadoEm).format('DD/MM/YYYY HH:mm:ss')}
                </Card.Header>
                <Card.Body>
                  <b>Observação:</b> {obs.parametros.observacao}
                </Card.Body>
              </Card>
            ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center w-100">
          {alterando ? (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={alterarObs}
              disabled
            >
              SALVANDO...
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={alterarObs}
            >
              SALVAR
            </button>
          )}
          <button
            type="button"
            className="btn btn-danger m-1"
            onClick={props.onHide}
          >
            CANCELAR
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
