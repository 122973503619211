import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import api from '../../../services/API';
import { useParams } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Toggle from 'react-bootstrap-toggle';
import { toast } from 'react-toastify';
import ModalExcluir from './ModalExcluir/index';
import ModalEditar from './ModalEditar/index';
import { useSelector } from 'react-redux';

const { SearchBar } = Search;

export default function DetalheRevenda() {
  const usuario = useSelector((state) => {
    return state.usuario;
  });

  const { cnpj } = useParams();
  const [revenda, setRevenda] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const [dataEditar, setDataEditar] = useState(null);
  const [id, setId] = useState('');
  const [produto, setProduto] = useState('');
  const [mostrarModalExcluir, setMostrarModalExcluir] = useState(false);
  const [mostrarModalEditar, setMostrarModalEditar] = useState(false);

  useEffect(() => {
    setBuscando(true);
    buscarRevenda();
    buscarProdutos();
    setBuscando(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const buscarProdutos = async () => {
    const resultado = await api.get(
      `/api/produtos_revenda?cnpjRevenda=${cnpj}`
    );
    if (resultado) {
      setProdutos(resultado.data.produtosRevenda);
    }
  };

  const buscarRevenda = async () => {
    const resultado = await api.get(`/api/revendas?cnpj=${cnpj}`);
    if (resultado) {
      const revenda = resultado.data.revendas[0];

      revenda.tipoPontuacao = revenda.tipoPontuacao ? 'Foto' : 'Sistema';

      let cnpj = revenda.cnpj.replace(/[^\d]/g, '');
      revenda.cnpj = cnpj.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5'
      );

      revenda.endereco = revenda.endComplemento
        ? `${revenda.endRua}, ${revenda.endNumero}<br/>${revenda.endBairro} - ${revenda.endComplemento}<br/>${revenda.endCidade} - ${revenda.endEstado}`
        : `${revenda.endRua}, ${revenda.endNumero}<br/>${revenda.endBairro}<br/>${revenda.endCidade} - ${revenda.endEstado}`;

      revenda.telefone = revenda.telefone
        ? `(${revenda.telefone.substring(0, 2)}) ${revenda.telefone.substring(
            2,
            6
          )}-${revenda.telefone.substring(6)}`
        : '';

      revenda.celular = revenda.telefone
        ? `(${revenda.celular.substring(0, 2)}) ${revenda.celular.substring(
            2,
            7
          )}-${revenda.celular.substring(7)}`
        : '';

      revenda.contato = '';
      if (revenda.telefone && revenda.celular) {
        revenda.contato = `${revenda.celular} / ${revenda.telefone}`;
      } else if (revenda.celular) {
        revenda.contato = `${revenda.celular}`;
      } else {
        revenda.contato = '';
      }

      setRevenda(revenda);
    }
  };

  const columns = [
    {
      text: 'Código Loja',
      dataField: 'codigo',
    },
    {
      text: 'Código Integralmédica',
      dataField: 'codigoPadrao',
    },
    {
      text: 'Descrição do Produto',
      dataField: 'nome',
    },

    {
      text: 'Pontos Unitário',
      dataField: 'valorPontos',
      formatter: (celula, valor) => {
        return Number(valor.valorPontos).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === 'asc') {
          return a - b;
        }
        return b - a;
      },
    },
    {
      isDummyField: true,
      dataField: 'ativo',
      text: 'Ativo',
      sort: true,
      hidden: [2].includes(usuario.permissao) ? false : true,
      formatter: (celula, valor) => {
        return (
          <Toggle
            onClick={(state) => updateStatus(state, valor.id)}
            on={<span>Sim</span>}
            off={<span>Não</span>}
            size="sm"
            offstyle="danger"
            active={valor.ativo === 1 ? true : false}
          />
        );
      },
    },
    {
      dataField: 'editar',
      isDummyField: true,
      text: 'Editar',
      sort: false,
      hidden: [1, 2].includes(usuario.permissao) ? false : true,
      formatter: (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => {
            abrirModalEditar(valor.id);
          }}
        >
          <i className="fas fa-edit"></i>
        </button>
      ),
    },
    {
      dataField: 'apagar',
      isDummyField: true,
      text: 'Apagar',
      sort: false,
      hidden: [2].includes(usuario.permissao) ? false : true,
      formatter: (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => {
            abrirModalExcluir(valor.id, valor.nome);
          }}
        >
          <i className="fas fa-trash f-red"></i>
        </button>
      ),
    },
  ];

  const defaultSorted = [
    {
      dataField: 'valorPontos',
      order: 'desc',
    },
  ];

  async function updateStatus(state, id) {
    const resultado = await api.put(`/api/produtos_revenda/${id}`, {
      ativo: state ? 1 : 0,
    });

    if (resultado) {
      toast.success('Produto atualizado com sucesso', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
    }

    setProdutos(
      produtos.map((d) => {
        if (d.id === id) {
          d.ativo = state ? 1 : 0;
        }
        return d;
      })
    );
  }

  const abrirModalExcluir = (id, produto) => {
    setProduto(produto);
    setId(id);
    setMostrarModalExcluir(true);
  };

  const fecharModalExcluir = () => {
    setProduto('');
    setId('');
    setMostrarModalExcluir(false);
  };

  const abrirModalEditar = (id) => {
    const dados = produtos.filter((d) => d.id === id);
    dados[0].cnpjRevenda = revenda.cnpj.replace(/\D/g, '');
    setDataEditar(dados[0]);
    setMostrarModalEditar(true);
  };

  const fecharModalEditar = () => {
    setDataEditar([]);
    setMostrarModalEditar(false);
  };

  const atualizarExclusao = (id) => {
    setProdutos(produtos.filter((d) => d.id !== id));
  };

  const atualizarProduto = (dataUpdated) => {
    setProdutos(
      produtos.map((d) => {
        if (d.id === dataUpdated.id) {
          d = { ...d, ...dataUpdated };
        }
        return d;
      })
    );
  };

  return (
    <Container fluid>
      {buscando ? (
        <h4 className="pt-3 pb-3 text-muted">Carregando...</h4>
      ) : (
        <h4 className="pt-3 pb-3 f-black">{revenda.nomeFantasia}</h4>
      )}
      <hr className="hr-loja mt-0 mb-4" />
      <Row>
        <Col md={6} lg={3} className="mb-2">
          <div className="card-detalhe p-3">
            <span>
              <b>Razão Social</b>
            </span>
            {buscando ? (
              <span className="text-muted">Carregando...</span>
            ) : (
              <span>{revenda.razaoSocial}</span>
            )}
            <br />
            <span>
              <b>Tipo de Pontuação</b>
            </span>
            {buscando ? (
              <span className="text-muted">Carregando...</span>
            ) : (
              <span>{revenda.tipoPontuacao}</span>
            )}
          </div>
        </Col>
        <Col md={6} lg={3} className="mb-2">
          <div className="card-detalhe p-3">
            <span>
              <b>CNPJ</b>
            </span>
            {buscando ? (
              <span className="text-muted">Carregando...</span>
            ) : (
              <span>{revenda.cnpj}</span>
            )}
            <br />
            <span>
              <b>Email</b>
            </span>
            {buscando ? (
              <span className="text-muted">Carregando...</span>
            ) : (
              <span>{revenda.email}</span>
            )}
          </div>
        </Col>
        <Col md={6} lg={3} className="mb-2">
          <div className="card-detalhe p-3">
            <span>
              <b>Responsável</b>
            </span>
            {buscando ? (
              <span className="text-muted">Carregando...</span>
            ) : (
              <span>{revenda.responsavel}</span>
            )}
            <br />
            <span>
              <b>Contato</b>
            </span>
            {buscando ? (
              <span className="text-muted">Carregando...</span>
            ) : (
              <span>{revenda.contato}</span>
            )}
          </div>
        </Col>
        <Col md={6} lg={3} className="mb-2">
          <div className="card-detalhe p-3">
            <span>
              <b>Endereço</b>
            </span>
            {buscando ? (
              <span className="text-muted">Carregando...</span>
            ) : (
              <span
                dangerouslySetInnerHTML={{ __html: revenda.endereco }}
              ></span>
            )}
            <br />
            <span>
              <b>CEP</b>
            </span>
            {buscando ? (
              <span className="text-muted">Carregando...</span>
            ) : (
              <span>{revenda.endCep}</span>
            )}
          </div>
        </Col>
      </Row>
      <h4 className="pt-3 pb-3 f-black">Produtos</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={produtos}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse">
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar"
                  className="input-theme"
                />
              </div>
              <BootstrapTable
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory()}
                defaultSorted={defaultSorted}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <ModalExcluir
        show={mostrarModalExcluir}
        onHide={fecharModalExcluir}
        produto={produto}
        id={id}
        atualizarExclusao={atualizarExclusao}
      />
      <ModalEditar
        show={mostrarModalEditar}
        onHide={fecharModalEditar}
        data={dataEditar}
        atualizarProduto={atualizarProduto}
      />
    </Container>
  );
}
