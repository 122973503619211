import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import api from '../../../../services/API';
import { toast } from 'react-toastify';
import { Form } from '@rocketseat/unform';

export default function ModalEditar(props) {
  const [editando, setEditando] = useState(false);
  const [data, setData] = useState({});

  const atualizar = async (e) => {
    e.preventDefault();
    setEditando(true);
    const resultado = await api.put(`/api/redes/${data.id}`, {
      nome: data.nome,
      regiao: data.regiao,
      valorPonto: data.valorPonto,
    });

    if (resultado) {
      toast.success(`Rede alterada com sucesso`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      props.onHide();
      props.atualizarRede(data);
    }
    setEditando(false);
  };

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props]);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Editar</Modal.Title>
      </Modal.Header>
      <Modal.Body className="mt-3 mb-3">
        <div className="d-flex flex-column align-items-center">
          <Form className="form" onSubmit={atualizar}>
            <label htmlFor="nome">Nome</label>
            <input
              id="nome"
              name="nome"
              type="text"
              placeholder="Nome"
              value={data.nome}
              onChange={(e) => setData({ ...data, nome: e.target.value })}
            ></input>
            <label htmlFor="regiao">Região</label>
            <input
              id="regiao"
              name="regiao"
              type="text"
              placeholder="Região"
              value={data.regiao}
              onChange={(e) => setData({ ...data, regiao: e.target.value })}
            ></input>
            <label htmlFor="valorPonto">Valor Ponto</label>
            <input
              id="valorPonto"
              name="valorPonto"
              type="number"
              step="0.01"
              placeholder="Valor Ponto"
              value={data.valorPonto}
              onChange={(e) => setData({ ...data, valorPonto: e.target.value })}
            ></input>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-center align-items-center w-100">
          {editando ? (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={atualizar}
              disabled
            >
              SALVANDO...
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-success m-1"
              onClick={atualizar}
            >
              SALVAR
            </button>
          )}
          <button
            type="button"
            className="btn btn-danger m-1"
            onClick={props.onHide}
          >
            CANCELAR
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
